.mapsection{
    height: 600px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .map-section-container{
        height: 100%;
        width: 100%;
        border: 1px solid green;
        
    }



}

@media only screen and (max-width: 500px) {
    .map-section-container{
        margin: 0px 20px !important;
    }

}