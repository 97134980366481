.topbar-parent {
  height: 300px;
  background: linear-gradient(rgba(0, 0, 0, 0.541), rgba(0, 0, 0, 0.541)),
    url("../Assets/top_img.webp");
    padding-left: 80px;

  .topbar-cont {
    display: flex;
    align-items: flex-start;
    // padding-top: 40px;
    flex-direction: column;
    justify-content: center;
    gap: calc(var(--gap) / 2);
    position: relative;
    .breadcrumb {
      display: flex;
      // background-color: var(--cream);
      // background-color: rgba(255, 255, 255, 0.349);
      // padding: 5px 10px;
      border-radius: 25px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
        color: rgba(255, 255, 255, 0.616);
        // color: rgba(255, 255, 255, 0.589);

        a {
          color: inherit;
          font-weight: inherit;
          position: relative;
          padding: 0px 2px;
          &.active {
            color: var(--cream);
          }

          &::after {
            content: "/";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            color: rgba(255, 255, 255, 0.616);

            font-weight: 400;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    h2 {
      margin-top: 100px;
      font-size: calc(var(--gap) * 3);
      line-height: 100%;
      color: var(--white);
    }
  }
}

@media only screen and (max-width: 500px) {
  .topbar-parent {
    padding-left: 0px !important;
    .topbar-cont {
      align-items: center;

      h2{
        font-size: 35px !important;
      }
    }
  }
}
