

// our pilers content

.ourpillers-parent {
  height: 470px;
  // padding-bottom: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  

  .overlay-img-second{
          height: 470px;
          width: 100%;
          background-image: url("../Assets/self-info-bg.png");
          
         

    .our-pillers-cont {
    
      // padding: 20px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 100px;
  
      .tag-founder {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
       
  
  
        h2 {
          width: fit-content;
          // border-bottom: 3px solid var(--cream);
        }
      }
  
      .founder-list {
        width: 100%;        
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        
        
  
        .founders {
          background-color: #ffffff;
          width: 300px;
          height: 250px;
          border-radius: var(--rad);
          padding: 10px 10px;
          display: flex;
          flex-direction: column;
          gap: var(--gap);
          align-items: center;
          box-shadow: var(--shadow);
          .founders-image {
            position: relative;
            width: 100%;
            height: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--accent);
            border-radius: var(--rad);
            
  
            &:hover {
              img {
                height: 275px;
              }
            }
  
            img {
              height: 250px;
              position: absolute;
              bottom: 0;
              transition: var(--trans);
            }
          }

         
  
          .founders-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
  
            h3 {
              padding: 0;
            }
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:1200px) {
  .ourpillers-parent{
    height: 900px !important;

   .overlay-img-second{
    height: 900px !important;
    

    .our-pillers-cont{
    

      .founder-list{
        display: grid !important;
        grid-template-columns: auto auto !important;
        justify-content: center;
        grid-row-gap: 120px !important;
      }
    }
   }
  }

} 

@media screen and (max-width:600px) {
 .ourpillers-parent{
  height: 1600px !important;
  

 .overlay-img-second{
  height: 1600px !important;
  padding: 20px 0 !important;
  .our-pillers-cont{
    gap: 100px !important;
.founder-list{
 display: flex !important;
 flex-direction: column;
}
}
 }
 }
 .founders-cont{
  h1{
    font-size: 50px !important;
  }
 }
}

