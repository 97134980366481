.counter-parent {
  height: 450px;
  background-color: #fff;

  .overlay-img {
    height: 450px;
    width: 100%;
    background-color: var(--cream2);

    .counter-cont {
      display: flex;
      flex-direction: column;

      align-items: center;
      padding: 30px 20px;
      gap: 60px;

      .counter-tag {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 50px;

          text-align: center;
        }
      }

      .counter-number {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .counter-list {
          display: flex;
          flex-direction: column;
          width: 250px;
          height: 200px;
          align-items: center;
          justify-content: center;
          border: 1px;
          border-style: solid;
          border-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0),
              rgb(193, 193, 193),
              rgba(0, 0, 0, 0)
            )
            1 100%;
          p {
            max-width: 20ch;
          }

          .counter-list-top {
            width: 120px;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;

            .counter-round {
              height: 100px;
              width: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              gap: 5px;
              padding-top: 20px;

              h3 {
                height: fit-content;
                width: fit-content;
                padding: 0;
                font-weight: 700;
                font-size: 60px;
              }
              h5 {
                font-size: 25px;
                color: black;
              }
            }
          }

          .counter-list-bottom {
            width: 200 px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              font-size: 16px;
              text-align: center;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .counter-parent {
    height: 800px;

    .overlay-img {
      height: 800px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .counter-parent {
    height: 1250px;

    .overlay-img {
      height: 1250px;
    }
  }
  .counter-list {
    width: 100% !important;

    p {
      max-width: none !important;
    }
  }
}
