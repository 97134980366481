.products-sec {
  height: 300px;
  background-image: url("../Assets/top_img.webp");

  .overlay {
    width: 100%;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.476);

    .products-cont {
      display: flex;
      justify-content: center;
      align-items: center;

      // h1 {
      //   padding-top: 50px;
      //   color: var(--white);
      //   font-size: 60px;
      //   line-height: 38px;
      // }
    }
  }
}

// products
.productss-parent{
  background: url("../Assets/self-info-bg.png");
}

.productss-cont {
  padding: 50px 0px !important;
  position: relative;
  z-index: 9 !important;

  .swiper {
    padding: 10px 10px 20px 10px;
    width: 100%;
   
    .swiper-pagination-fraction {
      color: #05614f !important;
    }
  }

  .swiper-button-next, 
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    box-shadow: var(--shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #05614f;
    font-size: 18px;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;

    
  }

  .swiper-button-next {
    right: 0px; // Move the next button away from the box
   

  }

  .swiper-button-prev {
    left: 0px; // Move the prev button away from the box
    
  }

  .swiper-pagination-fraction {
    color: #05614f !important;
  }


  .swiperslides-products {
    padding: 16px;
    display: flex;
    align-items: stretch;
    box-shadow: var(--shadow);
    border-radius: 10px;
    overflow: hidden;
    gap: 30px;
    background-color: #fff;
    

    .swiperslides-left {
      width: 300px;
      min-height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 8px;
      .products-img {
        height: 100%;
        width: 300px;
        
        transition: var(--trans);
      }

   

      &:hover {
        .products-img {
          transform: scale(1.15) rotate(5deg);
        }
      }
    }

    .swiperslides-right {
      width: calc(100% - 300px);
      min-height: 250px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 800px) {
  .swiperslides-products {
    flex-direction: column;
    align-items: center;

    .swiperslides-left {
      width: 100% !important;

      .products-img {
        width: 100% !important;
        height: 400px !important;
      
      }
    }
    .swiperslides-right {
      width: 100% !important;
      align-items: center;
    }
  }
}

.sugar-img {
  background-image: url("../Assets/raw/sugar.webp");
}

.distlillery-img {
  background-image: url("../Assets/raw/distillery.webp");
}

.Cogeneration-img {
  background-image: url("../Assets/raw/cogeneration.webp");
}

.Fertiliser-img {
  background-image: url("../Assets/raw/fertilizer.webp");
}
