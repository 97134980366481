
.swipe-top {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 999;

  button {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: var(--accent);
    cursor: pointer;
    position: relative;

    .icon-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .rotating-img {
        position: absolute;
        width: 120px; /* Adjust size of rotating image */
        height: 120px;
        background-image: url('../Assets/Onkar\ Wrold_Name1.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        animation: rotate 10s linear infinite;
        z-index: -1; /* Ensure it rotates behind the SVG */
      }

      .svg-icon {
        font-size: 40px;
        color: white;
        z-index: 1; /* Ensure SVG stays on top */
      }
    }
  }
}

/* Rotating animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive styles for tablets and mobile */
@media (max-width: 768px) {
  .swipe-top {
    right: 30px !important;
    bottom: 30px !important;

    button {
      height: 60px !important;
      width: 60px !important;

      .icon-wrapper {
        .rotating-img {
          width: 100px !important;
          height: 100px !important;
        }

        .svg-icon {
          font-size: 35px !important;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .swipe-top {
    right: 20px !important;
    bottom: 20px !important;

    button {
      height: 50px !important;
      width: 50px !important;

      .icon-wrapper {
        .rotating-img {
          width: 80px !important;
          height: 80px !important;
        }

        .svg-icon {
          font-size: 30px !important;
        }
      }
    }
  }
}





// footer starts from here--------------------------------------
.footer-parent {
  background: #051308;
  height: 260px;
padding:0px 10px;
  .footer-cont {
    width: 100%;
    padding: 30px 0;
    display: flex;
    gap: var(--gap);

    .footer-logo {
      width: 30%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: var(--gap);
      h3 {
        width: fit-content;
        color: white;
        padding: 2px 0px;
        border-bottom: 2px solid white;
      }

      p {
        width: 80%;
        font-family: "Poppins", sans-serif;
        text-align: left;
        font-size: 12px;
        line-height: 20px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.76);
       
      }

      .logo-name {
        display: flex;
        align-items: center;
        gap: var(--gap);

        .footer-logo-img {
          height: 60px;
          width: 60px;
          background-image: url("../Assets/raw/onkarLogo.png");
          background-repeat: no-repeat;
        }
        h2 {
          color: white;
          font-size: 20px;
        }
      }
    }

    .links {
      width: 20%;
      display: flex;
      flex-direction: column;
      gap: var(--gap);
      align-items: left;

      h3 {
        width: fit-content;
        color: white;
        padding: 2px 0px;
        border-bottom: 2px solid white;
      }

      .links-tags {
        display: flex;
        flex-direction: column;
        a,
        p {
          font-family: "Poppins", sans-serif;
          text-align: justify;
          font-size: 12px;
          line-height: 28px;
          letter-spacing: 1px;
          word-spacing: 2px;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.76);
          text-transform: capitalize;

          &:hover {
            color: var(--cream);
            cursor: pointer;
          }
        }
      }
    }

    .legal {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: left;
      gap: var(--gap);

      h3 {
        color: white;
        padding: 2px 0px;
        border-bottom: 2px solid white;
        width: fit-content;
      }

      .legal-tags {
        display: flex;
        flex-direction: column;
        a,
        p {
          font-family: "Poppins", sans-serif;
          text-align: justify;
          font-size: 12px;
          line-height: 28px;
          letter-spacing: 1px;
          word-spacing: 2px;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.76);
          text-transform: capitalize;

          &:hover {
            color: var(--cream);
            cursor: pointer;
          }
        }
      }
    }

    .contact-info {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: var(--gap);

      h3 {
        color: white;
        padding: 2px 0px;
        border-bottom: 2px solid white;
        width: fit-content;
      }
      .gmail {
        width: 100%;
        display: flex;

        align-items: center;
        gap: 20px;

        .contact-icon {
          height: 30px;
          width: 30px;
          background-color: var(--cream2);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 5px;

          svg {
            font-size: 20px;
            color: var(--accent);
          }
        }

        p {
          font-family: "Poppins", sans-serif;
          text-align: justify;
          font-size: 12px;
          line-height: 18px;
          text-align: left;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.76);
          text-transform: lowercase;



          &:hover {
            color: var(--cream) ;
            cursor: pointer;
          }
        }
      }
      .location {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 20px;

        .contact-icon {
          height: 30px;
          width: 30px;
          background-color: var(--cream2);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 5px;

          svg {
            font-size: 20px;
            color: var(--accent);
          }
        }
        p {
          width: fit-content;
          font-family: "Poppins", sans-serif;
          text-align: justify;
          font-size: 12px;
          line-height: 18px;
          text-align: left;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.76);

          &:hover {
            color: var(--cream);
            cursor: pointer;
          }
        }
      }
    }
  }
}
.copyright-info {
  height: 60px;
  width: 100%;
  background-color: black;

  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .copyright-content {
      width: 100%;

      p {
        font-family: "Poppins", sans-serif;
        text-align: justify;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.76);
        text-transform: capitalize;


        a{
          color:white;
          font-weight: 600;
        }
      }
    }
  }

  // .social-media-icons {
  //   display: flex;
  //   gap: var(--gap);

  //   justify-content: flex-end;
  //   width: 50%;

  //   .icon {
  //     height: 30px;
  //     width: 30px;
  //     background-color: white;
  //     border-radius: 50%;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     padding: 5px 5px;

  //     svg {
  //       color: var(--accent);
  //       font-size: 20px;
  //     }
  //   }
  // }
}

@media only screen and (max-width: 1200px) {
  .footer-parent {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 1000px) {
  .footer-parent {
    height: auto;

    .footer-cont {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;

      .footer-logo {
        width: 100%;

        p {
          text-align: center;
        }
      }

      .links {
        width: 100%;
      }
      .legal {
        width: 100%;
      }

      .contact-info {
        width: 100%;

        p {
          width: 100%;
          text-wrap: wrap;
        }
        .gmail{

          align-items: left !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact-info {
    .gmail {
      flex-direction: row;
      
    }

   
  }

  .footer-logo-img{
    display: flex !important;
    justify-content: center !important;
    height: 100px !important;
    width: 100px !important;
  }
}

@media only screen and (max-width: 500px) {
  .footer-parent {
    height: 800px !important;
    padding: 20px 10px !important;
    .footer-cont {
      display: flex;
      flex-direction: column !important;
      align-items: center !important;

      

      .footer-logo,
      .links,
      .legal,
      .contact-info {
        width: 100%;
        flex-direction: column;
        align-items: center;
        
        p{
          text-align: left !important;
        }


        .links-tags,.legal-tags{
           align-items: center;
        }
      }

      .contact-info {
        margin-left: 20px !important;
      }
    }
   
  }
  .copyright-info {
    height: 120px;
    padding: 20px 0px !important;

    .copyright {
      flex-direction: column-reverse;
      gap: var(--gap);

      .copyright-content {
        width: 100%;
        align-items: center;

        p {
          text-align: center !important;
        }
      }
      // .social-media-icons {
      //   width: 100%;
      //   justify-content: center;
      //   align-items: center;
      // }
    }
  }

  .contact-info {
    .gmail {
      flex-direction: row;
    }
  }
}
