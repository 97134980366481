.twoColSec {
  height: auto;
 
  // border:1px solid red;

  padding: 50px 10px;
 

  .twoColSec-cont {
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: var(--shadow);
    gap: 20px;
    height: 100%;
    min-height: 300px;
    // border:1px solid red;
    padding: 20px;

    .sec-left {
      height: auto;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      border-radius: 5px;
     

      .img-box {
        width: 100%;
        height: 100%;
        transition: var(--trans);
        // box-shadow: var(--shadow);
        background: rgb(209, 209, 209);
        border: 1px solid rgb(228, 226, 226);
      }

      &:hover {
        .img-box {
          transform: scale(1.12) rotate(5deg);
          
        }
      }
    }

    .sec-right {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;

      // h2,
      //  {
      //   margin-bottom: var(--gap);
      // }
    }
  }
}





@media only screen and (max-width:900px){
  .twoColSec{
    .twoColSec-cont{
      flex-direction: column !important;


      .sec-left{
        width: 100%;aspect-ratio: 1;
      }
      .sec-right{
        width: 100%;height: auto;
        justify-content: center;align-items: center;

        h2{
          text-align: center;
        }
      }
    }
  }
}