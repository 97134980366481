.factory-parent-new {
  // padding:100px 0px;
  display: flex;
  height: auto;
  align-items: stretch;
  justify-content: center;

  &:nth-child(odd) {
    flex-direction: row-reverse !important;
  }
  .factory-new-left {
    width: 50%;
    height: auto;
    // background-color: red;
  }
  .factory-new-right {
    width: 50%;
    height: 100%;
    background-color: var(--accent);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: calc(var(--gap) - 5px);
    padding: 40px 30px;

   .factory-content{
    width: 75%;
    height: 100%;





    h2,
    h5 {
      color: white;
    }

    h5 {
      font-size: calc(var(--gap) + 10px);
      margin-top: 10px
    }

    .fac_loc {
      background-color: white;
      color: var(--accent);
      padding: calc(var(--gap) / 2);
      border-radius: 25px;
    }
    p {
      color: white;
      opacity: 0.8;
    }
   }
  }
}

@media only screen and (max-width: 850px) {
  .factory-parent-new {
    flex-direction: column;
    height: auto;

    &:nth-child(odd) {
      flex-direction: column !important;
    }

    .factory-new-left {
      width: 100%;
      aspect-ratio: 1;
    }
    .factory-new-right {
      width: 100%;
      height: auto;
      padding: 40px 10px;

      .fac_loc {
        font-size: 11px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .factory-parent-new {
    .factory-new-right {
      width: 100%;
      height: auto;
      padding: 40px 10px;
      align-items: center;
    }
  }
}


