.error-parent {
    height: 100vh;
    background-image: url("../Assets/raw/overlay\ image-two.png");
    display: flex;
    justify-content: center;
    align-items: center;
  
    .img-parent {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
  
      .error-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        color: white;
  
        h1 {
          font-size: 150px;
          font-weight: bold;        
          margin: 0;
          background: linear-gradient(90deg, #05614f, #214e45);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;          
          letter-spacing: 5px;
          transform: scale(1.2);
        }
  
        h2 {
          font-size: 60px;
          margin: 10px 0px 30px  0px;
          
        }
  
        
      }
    }
  }

  @media only screen and (max-width:500px){
    h2{
        font-size: 30px !important;
    }
  }
  