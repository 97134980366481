.header {
  position: fixed;
  z-index: 999999999;
  top: 50px;
  transition: var(--trans);

  &.scrolled {
    top: 10px;
  }
  .header-cont {
    .header-body {
      display: flex;
      align-items: center;
      background: var(--cream2);
      padding: 5px;
      justify-content: flex-end;
      border-radius: 100px;
      position: relative;
      box-shadow: var(--shadow);

      &.scrolled {
        .icon {
          height: 70px;
          padding: 4px;
          background: #c5b797;
        }
        h2 {
          top: 55%;
          left: 165% !important;
        }
      }

      .icon {
        height: 100px;
        aspect-ratio: 1;
        background: #c5b797;
        position: absolute;
        left: 0px;
        border-radius: 100px;
        padding: 5px;
        transition: var(--trans);

        h2 {
          position: absolute;
          top: 53%;
          left: 145%;
          transform: translate(-50%, -50%);
          font-size: 30px;
          font-weight: 700;
          color: var(--accent);
          text-transform: uppercase;
        }

        .logo {
          height: 100%;
          aspect-ratio: 1;
          background: url("../Assets//raw/onkarLogo.png");
        }
      }

      .links {
        display: flex;
        align-items: center;
        gap: var(--gap);

        a:not(.btn) {
          color: var(--black);
          font-weight: 500;

          &::after {
            display: block;
            content: "";
            height: 3px;
            width: 0%;
            background: var(--accent);
            border-radius: 10px;
            transition: all 0.2s ease-out;
          }

          &.active {
            color: var(--accent);
          }

          &:hover {
            color: var(--accent);
            &::after {
              width: 100%;
            }
          }
        }
      }
      .nav-icon {
        padding: 5px;
        display: none;
        font-size: 30px;
      }
    }
  }
}

.dropbtn {
  display: flex;
  align-items: center;

  .dropicon {
    font-size: 25px;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 970px) {
  .header-body {
    height: 50px;
    width: auto;
    .links {
      display: none !important;
    }

    .nav-icon {
      padding: 0;
      display: block !important;
      cursor: pointer;
    }

    .responNav {
      display: block;
      width: 200px;
      height: 400px;
      background-color: var(--cream2);
      position: absolute;
      right: 10px !important;
      top: 105% !important;
      // left: 70px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--gap);
      border-radius: var(--rad);
      transition: ease-in-out;

      a {
        color: black !important;

        &:hover {
          color: var(--accent !important);
        }
      }
    }

    .icon {
      width: 100px;
    }

    &.scrolled {
      .icon {
        width: 50px;
      }
    }
  }

  .dropbtn {
    font-weight: bold !important;
  }

  .dropdown-content {
    left: -170% !important;
    border-radius: 5px !important;
  }
}
.nav-overlay {
  height: 100%;
  width: 100vw;
  background: rgba(0, 0, 0, 0.704);
  position: absolute;
  top: 0;
  z-index: 10;
  position: fixed;
}
@media screen and (max-width: 500px) {
  .header {
    .header-cont {
      .header-body {
        .responNav {
          width: 100%;
          right: 0 !important;
          top: 160% !important;

          .dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .dropdown-content {
              position: static !important;
              height: fit-content !important;
              display: block;
              // overflow: hidden;

              a {
                text-align: center;
              }
            }

      
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  font-family: "Poppins", sans-serif;
  color: #1a1a1af5 !important;
  background: var(--cream2);
  font-weight: 500;
  font-size: 16px;
  border: none;
  cursor: pointer;
  color: var(--black);
}

.dropdown-content {
  // margin-top:5px;
  display: none;
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  left: -20px;
  top: 100%;
  // background-color: #f9f9f9;
  background: var(--cream2);
  min-width: 100px;
  // box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: var(--black);
  font-weight: 500;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background: var(--cream2);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  // background-color: #3e8e41;
}

.active {
  font-weight: bold;
}
