:root {
  --black: #1a1a1a;
  --white: #ffffff;
  --accent: #05614f;
  --accent2:#72C144;
  --cream2: #EEEAC7;
  --cream: #9C8853;
  --text: rgb(107, 107, 107);
  --gap: 20px;
  --grey: #f6f6f6;
  --max-width: 1400px;
  --trans: all 0.4s ease-in-out;
  --shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  --rad: 10px;
  --semi-white: rgba(255, 255, 255, 0.76);
}

*::before,
*::after,
* {
  box-sizing: border-box;
  // border: 1px solid red;
  margin: 0px;
  font-family: "Poppins", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap");
// font-family: 'Poppins', sans-serif;
// font-family: "Teko", sans-serif;

body {
  width: 100%;
  overflow-x: hidden;
  font-weight: 700;
}

html {
  scroll-behavior: smooth;
}

.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Teko", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: var(--accent);
  line-height: 90%;


  &::selection {
    background-color: var(--accent);
    color: var(--white);
  }

  &::-moz-selection {
    background-color: var(--accent);
    color: var(--white);
  }
}

h2 {
  font-size: 38px;
}

h3 {
  font-size: 25px;
}

p {
  text-align: left;
  font-size: 1rem;
  line-height: 28px;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-weight: 300;
  color: var(--text);

  &::selection {
    background-color: var(--accent);
    color: var(--white);
  }

  &::-moz-selection {
    background-color: var(--accent);
    color: var(--white);
  }
}

.bg-img-cover {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.bg-img-contain {
  background-position: center center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.parent {
  width: 100vw;
  position: relative;

  .cont {
    max-width: var(--max-width);
    margin: auto;
    padding: 0px 10px;
    height: 100%;
    // background: rgba(102, 51, 153, 0.073);
  }
}


.btn {
  cursor: pointer;
  border: none;
  border: 3px solid var(--accent);
  background: transparent;
  color: var(--accent);
  font-size: 16px;
  padding: 5px 30px;
  border-radius: 200px;
  text-decoration: none;
  transition: var(--trans);
  font-weight: 600;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 5px;
  height: fit-content;
  position: relative;

  &:hover {
    color: var(--white) !important;
    background: var(--accent);
  }
}

a {
  text-decoration: none;
  cursor: pointer;
}

.heading {
  position: relative;
  overflow: hidden;
  transition: var(--trans);
  padding:5px 0px;

  &::after {
    position: absolute;
    width: 100%;
    content: "";
    bottom: 0;
    left: -100%;
    height: 2px;
    background-color: var(--accent);
    animation: linewipe 3s linear infinite;

    @keyframes linewipe {
      0% {
        left: -100%;
      }

      50% {
        left: 100%;
      }

      100% {
        left: -100%;
      }
    }
  }
}

.page_header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 5px 5px !important;
  overflow: hidden;
  margin-top: 50px;

  .textName {
    position: relative;
    z-index: 3;
    // height: 100%;
    font-size: 30px;
    color: white;
    background-color: var(--accent);
    padding: 10px 10px 10px 10px !important;
    line-height: 100%;
  }

  &::after {
    content: "";
    height: 120%;
    width: 120%;
    background: linear-gradient(white 50%, transparent 50%, transparent 60%, white 60%);
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: rotateAfter 4s linear infinite;

    @keyframes rotateAfter {
      from {
        transform: translate(-50%, -50%) rotate(0);
      }

      to {
        transform: translate(-50%, -50%) rotate(360deg);

      }

    }
  }


}

.swiper-button-prev:after,
.swiper-button-next:after {

  font-size: 20px !important;
  border-radius: 100%;
  background-color: var(--cream2);
  display: flex;
  color: var(--accent) !important;
  justify-content: center;
  align-items: center;
  height: 40px;
  aspect-ratio: 1;
}

@media only screen and (max-width:500px) {

  .swiper-button-prev:after,
  .swiper-button-next:after {

    font-size: 20px !important;

  }
}



@media only screen and (max-width:600px){
  .page_header{
    .textName{
      font-size: 24px;
    }
  }
}

@media (max-width:500px){
  p{
    text-align: center !important;
  }
}

@media only screen and (max-width:800px){
  .heading,
h1,
h2,
h3,
h4,
h5,
h6 {

  line-height:100% !important;

}
}


.highlight{
  color: var(--cream2);
  font-family: "Teko", sans-serif;
}