.environment-sec {
  height: fit-content;
  background-image: url("../Assets/top_img.webp");

  .overlay {
    width: 100%;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.476);

    .environment-cont {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.environment-parent {
  width: 100%;
  padding: 50px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../Assets/self-info-bg.png");

  .environment-container {
    width: 100%;
    display: flex;
    align-items: stretch; // Ensures the image aligns properly
    gap: 25px;
    flex-wrap: wrap;
    box-shadow: var(--shadow);
    border-radius: 10px;
    padding: 15px;
    background-color: #fff;

    .environment-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start; // Aligns all content to the top

      .list-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .list-div {
          display: flex;
          gap: 10px;
          align-items: flex-start;
         

          .env-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: var(--cream2);
            color: var(--accent);
            height: 25px;
            width: 25px;
            font-size: 18px;
            font-weight: bold;
            flex-shrink: 0;
          }
        }
      }
    }

    .environment-right {
      flex: 1;
      display: flex;
      justify-content: center;
      // padding-right: 30px;
      align-items: center; // Centers the image vertically
      overflow: hidden;
      border-radius: var(--rad);

      .env-right-img {
        width: 100%;
        height: 100%;
        background: url("../Assets/raw/environment.webp") center/cover no-repeat;

        transition: var(--trans);
      }

      &:hover {
        .env-right-img {
          transform: scale(1.2) rotate(5deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .environment-container {
    flex-direction: column-reverse !important;

    .environment-left,
    .environment-right {
      width: 100% !important;
    }

    .environment-right {
      .env-right-img {
        aspect-ratio: 2 / 1 !important;
      }
    }
    .environment-right {
      padding-right: 0px !important;
    }
  }
}

@media only screen and (max-width: 500px) {
  .environment-container {
    .environment-right {
      .env-right-img {
        aspect-ratio: 1 / 1 !important;
      }
    }

    .environment-left {
  h2{
    width: 100%;text-align: center;
  }
      .list-container {
        .list-div {
          // flex-direction: column !important;
          align-items: flex-start !important;
        }
      }
    }

    .env-icon {
      height: 30px !important;
      width: 30px !important;
      font-size: 16px !important;
    }
    .environment-right {
      padding-right: 0px !important;
    }
  }
}
