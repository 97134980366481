.blog-sec {
  background-image: url("../Assets/raw/bg5.png");
}

.blog-section-parent {
  height: auto;
  width: 100%;

  .blog-section-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(var(--gap));

    padding: 20px 0px;
    flex-direction: column;

    .search {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        width: 50%;
        padding: calc(var(--gap) / 2);
        outline: 2px solid black;
        border: none;
        border-radius: calc(var(--gap) / 3);

        &:focus {
          outline: 2px solid var(--accent);
        }
      }
    }

    .blogs {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: calc(var(--gap));
      flex-wrap: wrap;
      width: 100%;
    }
  }
}

// blogCard compoonent

.card {
  height: auto;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(var(--gap) / 2);
  background-color: white;
  box-shadow: var(--shadow);
  border-radius: var(--gap);
  padding: calc(var(--gap) / 2);
  .top {
    width: 100%;
    height: 200px;
    border-radius: calc(var(--gap) / 2);
  }
  .middle {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date,
    .category {
      // background-color: var(--accent);
      // padding: calc(var(--gap)/6) calc(var(--gap)/2);
      border-radius: var(--gap);

      p {
        color: var(--accent);
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .bottom {
    width: 100%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .heading {
      padding-bottom: none !important;
      font-size: 24px;
      color: var(--black);
      line-height: 120%;
    }

    p {
      text-align: justify;
      //   text-align-last: center;
      line-height: 130%;
      font-size: 12px;
    }
  }
}

// blog view css

.blog-view-parent {
  height: auto;
  width: 100%;

  .blog-view-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--gap) 0px;
    gap: var(--gap);
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .blogs-image {
      width: 80%;
      height: 400px;
      padding: var(--gap);
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      border-radius: calc(var(--gap) / 2) !important;

      overflow: hidden;
      .middle {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .date,
        .category {
          color: var(--accent);
          background: var(--cream);
          padding: 5px 15px;
          border-radius: 50px;
          box-shadow: var(--shadow);
        }
      }
    }
  }

  .bottom {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    // gap: calc(var(--gap) / 2);
    .heading {
      padding-bottom: none !important;
      font-size: 24px;
      color: var(--black);
      line-height: 110%;
      font-weight: 500;
    }
    p 
    {
      text-align: left;
    }
  }
}
