.gallery-image {
  height: 350px;
  aspect-ratio: 1 / 1;
  border-radius: calc(var(--gap) / 2);
  box-shadow: var(--shadow);
  overflow: hidden;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-position: center center;
  }
}

.gallery-parent {
  width: 100%;
  margin-top: 100px;
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  gap: var(--gap);
  width: 100%;
  .gallery-cont {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: var(--gap);
    flex-wrap: wrap;
    width: 100%;
  }
}

// .image-model {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   left: 0%;
//   z-index: 1000;
//   top: 0%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: rgba(255, 255, 255, 0.25);
//   box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
//   backdrop-filter: blur(12px);
//   -webkit-backdrop-filter: blur(12px);
//   img {
//     width: 80%;
//     height: 80%;
//     aspect-ratio: 3 / 1;
//     border-radius: calc(var(--gap) / 2);
//   }
// }
.image-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6); /* Darker background for better focus on the image */
  z-index: 1000;
  backdrop-filter: blur(10px); /* Subtle blur for background elements */
  transition: opacity 0.3s ease; /* Smooth transition for opening */
}

.image-model img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5); /* Adds depth and focus to the image */
  animation: fadeIn 0.5s ease-in-out; /* Animation for a smooth entrance */
}

.back_btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.8); /* Light background with transparency */
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease; /* Smooth background transition */
}

.back_btn:hover {
  background: rgba(255, 255, 255, 1); /* Full white on hover */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

// 
.back_btn 
{
  background: var(--accent);
  color: var(--white);
  width: 50px;
  height: 50px;
  position: absolute;
  right: 7%;
  top: 7%;
  border-radius: 50%;
  box-shadow: var(--shadow);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
}
