.privacy-parent{
    height: auto;
    margin-top: 150px;


    .privacy-cont{
       display: flex;
       flex-direction: column;
       gap: var(--gap);
       padding: 20px 20px;


       .top-tag{
        text-align: center;
        font-size: 50px;
       }

      h2,h1,h3,h4{
        padding-bottom: 0;
        color: black;
      }

      p{
        span{
            font-size: 20px;
            font-weight: 400;
        }

        text-align: justify;
        word-spacing: 10px;
      }

ul{
    line-height: 30px;
}
      li{
        
        font-weight: 300;
        
      }
    }
}