// .swiper-pagination-fraction{
//     color:white;    
// }
.herosection-parent {
    height: 100vh;
    /* Full viewport height */
    width: 100%;
    /* Full width */
    position: relative;
    .mobile{
    display: none;
    }

    /* Position relative for overlay positioning */
    .heroSwiper {

        .swiper-button-prev:after,
        .swiper-button-next:after {

            display: none;
        }

        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}

.video-section {
    width: 100%;
    height: 100%;
    position: relative;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.react-player video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    text-align: center;

    // background: linear-gradient(to bottom,
    //         rgba(0, 0, 0, 0),
    //         rgba(0, 0, 0, 0.615));
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 50%,
            /* Transparent color at 70% */
            rgba(0, 0, 0, 0.615) 100%
            /* Dark color from 70% to 100% */
        );

    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
}

.hero-heading {
    color: white;
    text-align: center;
    font-size: 90px;
    line-height: 80%;
    // position: absolute;

    padding-bottom: 50px ;
    max-width: 35ch;
}






@media only screen and (max-width:500px) {
    .hero-heading {
        font-size: 20px;
padding-bottom:80px !important;
        line-height: 120%;
    }
}

@media only screen and (max-width:1920px) {

    h1{
        font-size: 65px !important;
        
    }
}






@media only screen and (max-width:500px) {
    .herosection-parent {
        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }
    }
}
