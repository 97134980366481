.hero-section {
  height: 100vh;
  position: relative;

  .swiper {
    width: 100%;
    height: 100%;

    .swiper-pagination-bullet {
      background: white !important;
      width: 10px !important;
      height: 10px !important;
      border-radius: 100px !important;
    }

    .swiper-slide {
      height: 100%;
      text-align: center;
      font-size: 18px;

      display: flex;
      justify-content: center;
      align-items: center;

      .overlay {
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.648);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;

        h1 {
          color: var(--white);
          font-size: 80px;
          line-height: 40px;
        }

        .btn {
          color: var(--white);
          border: 3px solid var(--white);

          &:hover {
            border: 3px solid var(--accent);
          }
        }
      }

      &:nth-child(1) {
        background: url("../Assets/raw/sl1.webp");
      }

      &:nth-child(2) {
        background: url("../Assets/raw/sl2.webp");
      }

      &:nth-child(3) {
        background: url("../Assets/raw/sl3.webp");
      }
    }
  }
}
.two-section-parent {
  height: auto;
  padding: 40px 0px;
  .two-section-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    .two-section-top,
    .two-section-bottom {
      display: flex;
      justify-content: center;
      align-items: stretch;
      height: auto;
      gap: 20px;
      padding: 20px;
      background: var(--white);
      box-shadow: var(--shadow);
      border-radius: 10px;

      .two-sec-right {
        width: 50%;
        height: auto;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .image {
          height: 100%;
          width: 100%;
          background: url("../Assets/raw/bg1.webp");
          border-radius: 5px;
        }
      }

      .two-sec-left {
        width: 50%;
        height: 100%;
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }

    .two-section-bottom {
      .two-sec-left {
        height: auto;
        width: 50%;
        .bottom-left-img {
          height: 100%;
          width: 100%;
          background: url("../Assets/raw/products.webp") !important;
          background-position: center center !important;
          background-size: cover !important;
          background-repeat: no-repeat;
          border-radius: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .hero-section {
    height: 100vh !important;

    .overlay {
      gap: 30px !important;
      padding: 0 20px;

      h1 {
        font-size: 52px !important;
        line-height: 44px !important;
      }
    }

    .slide-image {
      height: 1000px !important;
      justify-content: center !important;
      align-items: center !important;

      .overlay {
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }
}

.about-section {
  height: 500px;

  .about-section-cont {
    display: flex;

    .about-section-cont-left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .img-box {
        width: 80%;
        aspect-ratio: 1;
        background: url("../Assets/raw/bg1.png");
        border-radius: 10px;
      }
    }

    .about-section-cont-right {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .about-section-cont {
    gap: 10px;
  }

  .about-section-cont-left {
    width: 40% !important;

    .img-box {
      width: 95% !important;
    }
  }

  .about-section-cont-right {
    width: 60% !important;
  }

  .overlay {
    gap: 30px !important;
    padding: 0 20px;

    h1 {
      font-size: 68px !important;
      line-height: 44 / px !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .testimoneal-tag-cont {
    .swiper-pagination {
      margin-top: -110px !important;
    }

    .swiper {
      height: 480px !important;
      // margin-top: -110px !important;

      .swiper-slide {
        // height: 300px !important;

        // .testimoneal-cont{
        //   height: 300px !important;
        // }
      }
    }
  }

  .two-section-parent {
    .two-section-cont {
      .two-section-top {
        flex-direction: column;

        .two-sec-right {
          width: 100%;
          aspect-ratio: 1;
        }
        .two-sec-left {
          width: 100%;
          align-items: center;
        }
      }

      .two-section-bottom {
        flex-direction: column-reverse;

        .two-sec-left {
          width: 100%;
          aspect-ratio: 1;
        }
        .two-sec-right {
          width: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .overlay {
    gap: 30px !important;
    padding: 0 20px;

    h1 {
      font-size: 48px !important;
      line-height: 50px !important;
    }
  }

  .about-section {
    height: auto;
    padding: 50px 0px;
  }

  .about-section-cont {
    gap: 30px !important;
    flex-direction: column !important;
  }

  .about-section-cont-left {
    width: 100% !important;

    .img-box {
      width: clamp(350px, 60%, 800px) !important;
    }
  }

  .about-section-cont-right {
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 5px !important;

    p {
      width: clamp(300px, 95%, 600px);
    }
  }
}

@media only screen and (max-width: 500px) {
  .testimoneal-parent {
    height: 500px !important;

    .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      top: 280px !important;
    }
  }

  .testimoneal-tag-cont {
    .swiper-pagination {
      margin-top: 180px !important;
    }

    .swiper {
      height: 480px !important;
      margin-top: -110px !important;

      .swiper-slide {
        // height: 300px !important;

        .testimoneal-cont {
          height: 300px !important;
          padding: 20px 30px !important;
        }
      }
    }
  }
}



.swiper-pagination-bullet {
  background: var(--accent) !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 100px !important;
}

.swiper-pagination-bullet-active {
  width: 40px !important;
}

.testimoneals-parent {
  background-color: var(--cream2);
  height: fit-content;


  .swiper-pagination-fraction {
    color: #05614f !important;
  }

  .testimoneals-tag-cont {
    height: 100%;

    .test-tag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 40px;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;

      .testimoneal-cont {
        margin: 40px 0px;
        padding: 30px 0px;
        width: 800px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        gap: var(--gap);
        text-align: center !important;
        background-color: white;
        box-shadow: var(--shadow);
        border-radius: var(--rad);

        .testimonel-content {
          h2 {
            border-bottom: 3px solid var(--cream);
            padding-bottom: 10px;
          }
        }

        .testimoneal-para {
          .test-p {
            max-width: 600px;
            text-align: center;
          }
        }

        .desgintion {
          display: flex;
          gap: var(--gap);
          align-items: center;
          justify-content: center;

          img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
          }

          .name {
            padding-top: 20px;

            h5 {
              font-size: 20px;
            }

            p {
              height: fit-content;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
}

.blog-home-parent {
  width: 100%;
  height: auto;

  .blog-home-cont {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--gap);
    padding: 50px 0px;

    .blogList {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

.gallery-home-parent {
  height: 100%;
  width: 100%;
  background: var(--cream2);

  .gallery-home-overlay {
    background-image: url("../Assets/raw/overlay\ image.webp");
    height: 100%;
    width: 100%;
  }

  .gallery-home-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--gap);
    padding: 50px 0px;
    z-index: 200;
    width: 100%;

    // border: 1px solid red;
    .home-gallery-box {
      // border: 1px solid green;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: var(--gap);
      flex-wrap: wrap;
    }
  }
}


