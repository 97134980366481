.about-sec {
  height: 300px;
  background-image: url("../Assets/top_img.webp");

  .overlay {
    width: 100%;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.476);

    .about-cont {
      display: flex;
      justify-content: center;
      align-items: center;

      // h1 {
      //   padding-top: 50px;
      //   color: var(--white);
      //   font-size: 60px;
      //   line-height: 38px;
      // }
    }
  }
}

.about-section-para {
  .about-section-para-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    h3 {
      font-size: 28px;
      text-transform: capitalize;
    }

    .about-section-para-list {
      display: flex;
      gap: 20px;
      padding: 30px 0;

      .about-section-para-list-left {
        width: 50%;
        text-align: justify;
      }

      .about-section-para-list-right {
        width: 50%;
        text-align: justify;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .about-section-para-list {
    margin: 0 30px;

    .about-section-para-list-left {
      width: 100%;
    }

    .about-section-para-list-right {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .about-section-para-list {
    flex-direction: column;

    .about-section-para-list-left {
      width: 100% !important;
    }

    .about-section-para-list-right {
      width: 100% !important;
    }
  }
}

.about-section-counters {
  .about-section-cont {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .counters {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      position: relative;

      .count-div {
        width: 200px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
          font-size: 30px;

          .spantext {
            font-size: 12px;
          }
        }
      }

      p {
        font-size: 12px;
      }
    }
  }
}

.personality {
  background: #fff;
  // padding: 50px 10px;

  .overlay-img {
    // height: 600px;
    background: var(--cream2);

    .personality-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding-top:40px;
      // background: #ffffff;
      gap: 40px;

      p {
        text-align: center;
        padding: 0px 20px;
      }

      .mySwiper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        padding-bottom: 50px;
        

        .swiper-pagination-fraction {
          color: #05614f !important;
        }

        .swiper-horizontal
          > .swiper-pagination-bullets
          .swiper-pagination-bullet,
        .swiper-pagination-horizontal.swiper-pagination-bullets
          .swiper-pagination-bullet {
          background: var(--accent2);
        }

        @media only screen and (max-width: 1000px) {
        }

        .swiper-pagination-bullet {
          background: var(--accent) !important;
        }
      }

      .personality_twobox_cont {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        padding:16px;
        border-radius: 10px;
        gap: 20px;
        border-radius: 10px;
       


        .personality_img {
          width: 40%;
          height: 300px;
          border-radius: 10px;
        }

        .personality_img-1 {
          overflow: hidden;

          .image {
            height: 100%;
            width: 100%;
            background: url("../Assets/raw/distillery.webp");
            transition: var(--trans);
          }

          &:hover {
            .image {
              transform: scale(1.15) rotate(5deg);
            }
          }
        }

        .Cogeneration-img {
          overflow: hidden;
          .image {
            height: 100%;
            width: 100%;
            background-image: url("../Assets/raw/cogeneration.webp");

            transition: var(--trans);
          }

          &:hover {
            .image {
              transform: scale(1.15) rotate(5deg);
            }
          }
        }

        .Compost-img {
          overflow: hidden;
          .image {
            height: 100%;
            width: 100%;
            background-image: url("../Assets/raw/compost-fertilizer.webp");

            transition: var(--trans);
          }

          &:hover {
            .image {
              transform: scale(1.15) rotate(5deg);
            }
          }
        }

        .Bio-img {
          overflow: hidden;
          .image {
            height: 100%;
            width: 100%;
            background-image: url("../Assets/raw/bio\ cng.webp");

            transition: var(--trans);
          }

          &:hover {
            .image {
              transform: scale(1.15) rotate(5deg);
            }
          }
        }

        .personality_content {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          gap: 20px;
          width: 60%;
          padding: 20px;
          

          h3 {
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: 500;
            color: var(--accent); 
          
            .line {
              content: '';
              display: inline-block;
              width: 50px; 
              height: 2px; 
              background-color: var(--accent); 
              margin-right: 10px; 
            
          
          }
          }

          p {
            text-align: left;
            padding: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .personality {
    height: 1000px !important;

    .overlay-img {
      height: 1000px !important;
    }
  }

  .personality_twobox_cont {
    width: 100% !important;
    margin: 0 !important;
    flex-direction: column !important;

    .personality_img {
      width: 100% !important;
      height: 50%;
    }

    .personality_content {
      width: 100% !important;
      h3{
        width:100%;

        display: flex;align-items: center;justify-content: center;
      }
      p{
        text-align: center !important;
      }
    }

    .personality-container {
      p {
        padding: 0 10px !important;
        text-align: justify !important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .personality {
    height: 1350px !important;

    .overlay-img {
      height: 1350px !important;

      .personality_twobox_cont {
        gap: 40px !important;
        width: 100%;

        .personality_content {
          width: 100%;
          height: 500px !important;
        }
      }
    }
  }
}
