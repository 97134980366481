.factory-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  
  
}

.factory-section {
  width: 100%;
  display: flex; 
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  padding: 50px 0px 25px 0px;

  &.odd {
    background: var(--cream2);
  }

  &.even {

    background: url('../Assets/self-info-bg.png');
    
    
  }

  .numf {
    position: relative;
    top: -20px; /* Adjust as needed */
    background-color: #b89c63; /* Gold color */
    color: #ffffff;
    font-size: 30px;
    padding: 12px 20px 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: fit-content;
    margin: 0 auto 10px auto; /* Centers it above the box */
  }
  
}

.factory-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  max-width: 1300px;
  margin-bottom: 20px;
  box-sizing: border-box;
  box-shadow: var(--shadow);
  border-radius: 10px;
  background-color: #fff;

  
}


.factory-image {
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center; /* Vertically center the image */
  justify-content: center;
  
}

.factory-image img {
  max-width: 100%;  /* Ensure the image scales correctly without stretching */
  height: auto;
  object-fit: cover; /* Ensures the image covers the space without stretching */
  margin: 0 auto;
  border-radius: 5px;
  border: 1px solid rgb(228, 226, 226);
  
}

.factory-content {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;


  

  h2{
    font-size: 35px;
  }

  .addressf{
    // background-color: var(--cream2);
    color: black;

    font-weight: 400;
    border-radius: 5px;
    margin-top: -10px;
  }

  
  
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .factory-box {
    flex-direction: column !important;
    
  }
  .factory-image {
    padding: 0px 0 ;
  }
  .factory-content {
    align-items: center;
    text-align: center;

    p{
      text-align: center !important;
    }
  }

  .addressf{
    line-height: 1.2 !important;
    margin: 5px !important;
    font-size: 14px !important;
  }
}
