.contact-sec {
  height: 300px;
  background-image: url("../Assets/top_img.webp");

  .overlay {
    width: 100%;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.476);

    .contact-cont {
      display: flex;
      justify-content: center;
      align-items: center;

      
    }
  }
}

.contact-content {
  height:550px;
  background-color: white;

  .overlay-img-second {
    height: 550px;
    width: 100%;
    background-image: url("../Assets/self-info-bg.png");
    padding: 40px 0px;
    justify-content: center;
    align-items: center;
    display: flex;

    .contact-content-cont {
      display: flex;
      gap: 40px;
    



      .contact-left {
        width: 60%;
        // background-color: var(--cream);
        background-color: var(--cream2);

        border-radius: var(--rad);


        form {
          padding: 20px 20px;
          display: flex;
          flex-direction: column;
          gap: var(--gap);
          justify-content: center;
          align-items: center;

          input {
            width: 100%;
            padding: 20px 20px;
            height: 60px;
            border-radius: var(--rad);
            border: 1px solid transparent;

          }

          textarea {
            width: 100%;
            height: 130px;
            padding: 20px 20px;
            border-radius: var(--rad);
            border: none;

          }

        }


      }

      .contact-right {
        width: 40%;
        background-color: var(--cream2);
        border-radius: var(--rad);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        padding: 0px 40px;

        h3 {
          padding: 0;
          line-height: 24px;
        }

        p{
          line-height: 1.3;
        }

        


        .mail-id {
          width: 100%;
          height: 80px;
          background-color: var(--white);
          padding: 20px 20px;
          border-radius: var(--rad);
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 30px;


          p{

            a{
              color: var(--text);
            }
          }

          span {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: var(--accent);
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              font-size: 30px;
              color: var(--white);
            }


          }
        }

        .address {
          width: 100%;
          height: 140px;
          background-color: var(--white);
          padding: 20px 20px;
          border-radius: var(--rad);
          display: flex;
          
          gap: 10px;


          p {
            
            width:85%;
            line-height: 25px;

            a{
              color: var(--text);;
            }
          }

          span {
            // width: 15%;
            // height: 50px;
            padding:10px; 
            height:50px;
            width:50px;
            border-radius: 50%;
            background-color: var(--accent);
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              font-size: 30px;
              color: var(--white);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .contact-content {
    height: 1000px;


    .overlay-img-second {
      height: 1000px;

      .contact-content-cont {
        flex-direction: column !important;

        .contact-left {
          width: 100% !important;
        }

        .contact-right {
          width: 100% !important;
          padding: 20px 20px;

          .address {

            span {
              width: 60px;
              height: 50px;
            }
          }
        }

      }


    }
  }
}

@media only screen and (max-width: 400px) {

  .contact-right {
       height: 400px !important;

       h3{
        text-align: center !important;
       }
    .mail-id {
      padding: 0 10px !important;

      p {
        width: 70%;
        font-size: 13px;
      }

      span {
        width: 30%;
        height: 40px !important;
        width: 40px !important;

        svg {
          font-size: 30px !important;
        }
      }
    }

    .address {
      padding: 10px 10px !important;
      
   
      p {
        width: 70%;
        font-size: 13px;
        line-height: 20px;
        text-align: left !important;
      }

      span {
        width: 40px !important;
        height: 40px !important;

        svg {
          font-size: 25px !important;
        }
      }
    }
  }
}