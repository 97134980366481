.social-sec {
  height: 300px;
  background-image: url("../Assets/top_img.webp");

  .overlay {
    width: 100%;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.476);

    .social-cont {
      display: flex;
      justify-content: center;
      align-items: center;

     
    }
  }
}

.social-res-parent {
  height: 800px;
  background-color: #fff;

  .overlay-img {
    height: 800px;
    background: var(--cream2);
    


    .social-res-cont {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      gap: var(--gap);
      

      .social-cont-top {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap);

        .social-para {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            text-align: center;
          }
        }


      }

      .socail-res-section {
        width: 100%;
        height: 100%;
        display: flex;
        gap: var(--gap);
        flex-direction: column;
        justify-content: center;



        .our-intivaties {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: var(--gap);

          h3 {
            padding: 0;
          }
        }

        .social-page-divider {
          display: flex;
          gap: var(--gap);
        }

        .social-res-sidebar-left {
          width: 30%;
          height: 100%;

          border-radius: var(--rad);

          .social-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--gap);
            padding: 50px 0;

            .social-btn {
              height: 50px;
              width: 100%;
              border: none;
              background-color: var(--accent);
              color: white;
              font-weight: 700;
              font-size: 16px;
              border-radius: var(--rad);
              cursor: pointer;
            }

            .active-btn {
              height: 50px;
              width: 100%;
              border: none;
              background-color: var(--cream);
              color: white;
              font-weight: 700;
              font-size: 16px;
              border-radius: var(--rad);
              cursor: pointer;
            }
          }
        }

        .social-res-content-right {
          width: 70%;
          height: 100%;
          padding: 50px 0;

          .solar-power-plants {
            display: flex;
            flex-direction: column;
            gap: var(--gap);

            .solar-img {
              width: 100%;
              height: 300px;
              background-image: url("../Assets/raw/compost-fertilizer.webp");

              border-radius: var(--rad);
            }

            .waste-mange-img {
              background-image: url("../Assets/raw/waste-mangament.webp");
            }

            .community-eng-img {
              background-image: url("../Assets/raw/community\ management.webp");
            }

            .employee-welfare-img {
              background-image: url("../Assets/raw/employee\ welfare.webp");
            }

            .stakeholder-eng-img {
              background-image: url("../Assets/raw/stakeholder-engagement.webp");
            }


            .sustainable-prac-img {
              background-image: url("../Assets/raw/sustainable\ practices.webp");
            }



            .solar-tag {
              width: 100%;

              h2 {
                padding: 0;
              }
            }
          }
        }
      }

      .social-res-swiper {
        height: 70%;
        width: 100%;

        .mySwiper {
          width: 100%;
          height: 100%;

          .social-slides {
            display: flex;
            flex-direction: column;
            gap: var(--gap);
            height: 100%;
            width: 100%;
            padding: 20px 20px;
            

            .social-img {
              width: 100%;
              height: 300px;

              .img-section {
                border-radius: var(--rad);
                height: 100%;
                width: 100%;
                background-color: grey;
              }

              .stakeholder-swi-img {
                background-image: url("../Assets/raw/stakeholder-engagement.webp");
              }

              .compost-ferti-swi-img {
                background-image: url("../Assets/raw/compost-fertilizer.webp");
              }

              .waste-mange-swi-img {
                background-image: url("../Assets/raw/waste-mangament.webp");
              }

              .community-eng-swi-img {
                background-image: url("../Assets/raw/community\ management.webp");
              }

              .employee-wel-swi-img {
                background-image: url("../Assets/raw/employee\ welfare.webp");
              }

              .sustainable-prac-swi-img {
                background-image: url("../Assets/raw/sustainable\ practices.webp");
              }
            }

            .social-content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1550px) {
  .social-res-parent {
    height: 750px ;
  }
}



@media screen and (max-width: 1300px) {
  .social-res-parent {
    height:800px ;

    .our-intivaties {
      padding: 20px 20px;
    }

    .socail-res-section {
      padding: 0px 20px;
    }
  }
}

@media screen and (max-width:1000px) {
  .social-res-parent {
    height: 850px ;
  }
}

@media screen and (max-width:500px) {
 
.social-res-parent{
  height: 850px !important;

   ;

       .social-res-swiper{
height: 650px !important;
      }
    
.our-intivaties{
  h2{
    text-align: center;
  }
}
}
  

    h1 {
      font-size: 60px !important;
      text-align: center !important;
    }

    p {
      text-align: center !important;
    }
  }


@media screen and (max-width: 800px) {
  .social-res-parent {
    height:800px ;
    .overlay-img{
      height: 900px;
    }

    .socail-res-section {
      display: none !important;
    }
  }


  .swiper-slide {
    // height: 600px !important;
  }
}

@media screen and (max-width: 400px) {
  .social-res-parent {
    height: 1000px ;
   
    .socail-res-section {
      display: none !important;
    }
  }
}
